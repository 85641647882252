<template> 
  <card
    v-model="item"
    @openAuth="$emit('openAuth')"
    @delete="$emit('delete')"
    @refresh="$emit('refresh')"
    @getExternalLink="$emit('getExternalLink')"
  >
    <template #header>
      <b-row align-v="center" class="d-flex flex-nowrap justify-content-between w-100">

        <svg class="power-bi-logo ml-75" viewBox="0 0 630 630" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                    <stop stop-color="#EBBB14" offset="0%"></stop>
                    <stop stop-color="#B25400" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
                    <stop stop-color="#F9E583" offset="0%"></stop>
                    <stop stop-color="#DE9800" offset="100%"></stop>
                </linearGradient>
                <path d="M346,604 L346,630 L320,630 L153,630 C138.640597,630 127,618.359403 127,604 L127,183 C127,168.640597 138.640597,157 153,157 L320,157 C334.359403,157 346,168.640597 346,183 L346,604 Z" id="path-3"></path>
                <filter x="-9.1%" y="-6.3%" width="136.5%" height="116.9%" filterUnits="objectBoundingBox" id="filter-4">
                    <feOffset dx="20" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0530211976 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-5">
                    <stop stop-color="#F9E68B" offset="0%"></stop>
                    <stop stop-color="#F3CD32" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="PBI-Logo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group" transform="translate(77.500000, 0.000000)">
                    <rect id="Rectangle" fill="url(#linearGradient-1)" x="256" y="0" width="219" height="630" rx="26"></rect>
                    <g id="Combined-Shape">
                        <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                        <use fill="url(#linearGradient-2)" fill-rule="evenodd" xlink:href="#path-3"></use>
                    </g>
                    <path d="M219,604 L219,630 L193,630 L26,630 C11.6405965,630 1.75851975e-15,618.359403 0,604 L0,341 C-1.75851975e-15,326.640597 11.6405965,315 26,315 L193,315 C207.359403,315 219,326.640597 219,341 L219,604 Z" id="Combined-Shape" fill="url(#linearGradient-5)"></path>
                </g>
            </g>
        </svg>
        
        <h2 class="px-1 d-flex flex-nowrap">
          <span>
            Power BI
          </span>
        </h2>
        <feather-icon
          :icon="active ? 'CheckIcon' : 'XIcon'"
          :class="active ? 'text-primary' : 'text-danger'" 
          size="30"
        />
      </b-row>
    </template>
    <template #footer>
      <b-row class="d-flex justify-content-end microsoft-footer">
        <b-button class="microsoft-button p-50 mr-75 d-flex align-items-center" variant="light-gray" @click="$emit('openAuth')">
          <svg xmlns="http://www.w3.org/2000/svg" class="microsoft-logo mr-50" viewBox="0 0 23 23">
            <path fill="#f35325" d="M1 1h10v10H1z"/>
            <path fill="#81bc06" d="M12 1h10v10H12z"/>
            <path fill="#05a6f0" d="M1 12h10v10H1z"/>
            <path fill="#ffba08" d="M12 12h10v10H12z"/>
          </svg>
          <span>
            Authorize
          </span>
        </b-button>
      </b-row>
    </template>
  </card>
</template>

<script>
import { BImg, BIcon, BRow, BCol, BButton } from 'bootstrap-vue'
import Card from '@/layouts/components/ThirdPartyApps/Card.vue'

  export default {
    components: {
      BImg,
      BIcon,
      BRow,
      BCol,
      Card,
      BButton
    },
    props: {
      value: {
        type: Object,
        default: undefined,
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      active() {
        return this.item.token
      }
    }
  }
</script>

<style lang="scss" scoped>
.power-bi-logo {
  display: inline;
  height: 3em;
}

.microsoft-footer {
  height: 2.8em;
  .microsoft-button {
    font-size: 14px;
    .microsoft-logo {
      display: inline;
      height: 1.5em;
    }
  }
}
  
</style>